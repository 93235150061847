import React from 'react';
import Header from '../../components/siderbar';
import './styles.css';
import { FaFilePdf } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import InfoCard from '../../components/infoCard/InfoCard';
import questionIcon from '../../assets/info.png';

function MaisInformacoes() {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const estudos = [
        {
            titulo: t("dsm_5_manual"),
            links: [
                { idioma: "pt_br", url: "https://www.institutopebioetica.com.br/documentos/manual-diagnostico-e-estatistico-de-transtornos-mentais-dsm-5.pdf" },
                { idioma: "pt_pt", url: "https://dislex.co.pt/images/pdfs/DSM_V.pdf" },
                { idioma: "en", url: "https://repository.poltekkes-kaltim.ac.id/657/1/Diagnostic%20and%20statistical%20manual%20of%20mental%20disorders%20_%20DSM-5%20(%20PDFDrive.com%20).pdf" },
            ],
        },
        {
            titulo: t("autism_public_policies"),
            links: [
                { idioma: "TODOS", url: "https://pepsic.bvsalud.org/scielo.php?script=sci_arttext&pid=S1677-11682019000300019" },
            ],
        },
        {
            titulo: t("mchat_translation_validation"),
            links: [
                { idioma: "TODOS", url: "https://www.scielo.br/j/rpp/a/pXSnBjMQKDX43kK5xtJdnCJ/" },
            ],
        },
        {
            titulo: t("early_childhood_framework"),
            links: [
                { idioma: "TODOS", url: "https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/lei/l13257.htm" },
            ],
        },
        {
            titulo: t("child_health_booklet_girl"),
            links: [
                { idioma: "TODOS", url: "https://www.gov.br/saude/pt-br/assuntos/saude-de-a-a-z/s/saude-da-crianca/caderneta" },
            ],
        },
        {
            titulo: t("importance_of_screening"),
            links: [
                { idioma: "TODOS", url: "https://ojs.brazilianjournals.com.br/ojs/index.php/BJHR/article/view/28223" },
            ],
        },
        {
            titulo: t("brazilian_cars_tool"),
            links: [
                { idioma: "pt_br", url: "https://doi.org/10.1590/S0021-75572008000700001" },
                { idioma: "pt_pt", url: "https://doi.org/10.1590/S0021-75572008000700001" },
                { idioma: "en", url: "https://www.scielo.br/j/jped/a/3bMdRWRV33YgZTB8ZJTMjDJ/?format=pdf&lang=en" },
            ],
        },
        {
            titulo: t("interactive_screening_test"),
            links: [
                { idioma: "TODOS", url: "https://doi.org/10.1016/j.jpeds.2015.05.029" },
            ],
        }
    ];

    const handleClick = (links) => {
        console.log("clicou aqui com sucesso");
        console.log("Idioma atual:", currentLanguage);
        console.log("Links disponíveis:", links);

        const link = links.find((l) => l.idioma === currentLanguage || l.idioma === "TODOS");
        if (link) {
            window.open(link.url, "_blank");
        } else {
            console.warn("Nenhum link correspondente ao idioma encontrado.");
        }
    };
    return (
        <Header>
            <InfoCard title={t("more_information")} icon={questionIcon} />
            <div className='home-container'>
                <div className="informacoes-container">
                    <h2 className="informacoes-titulo">{t("more_info_title")}</h2>
                    <p className="informacoes-subtitulo">
                        {t("more_info_subtitle")}
                    </p>
                    {estudos.map((estudo, index) => (
                        <div
                            className="informacoes-card"
                            key={index}
                            onClick={() => handleClick(estudo.links)}
                        >
                            <div>
                                <FaFilePdf className="pdf-icon" />
                            </div>
                            <p className="informacoes-card-texto">{estudo.titulo}</p>
                        </div>
                    ))}

                    <div className="informacoes-referencias">
                        <h4>{t("references_title")}</h4>
                        <p>
                            Robins DL, Fein D, Barton M. The Modified Checklist for Autism in Toddlers, Revised with Follow-Up (M-CHAT-R/F)TM. Self-published; 2009.
                        </p>
                        <p>
                            Losapio MF, Siquara GM, Lampreia C, Lázaro CP, Pondé MP. Translation into Brazilian Portuguese and validation of the M-CHAT-R/F scale for early screening of autism spectrum disorder. Revista Paulista de Pediatria. 2023;41.
                            <a href="https://www.scielo.br/j/rpp/a/pXSnBjMQKDX43kK5xtJdnCJ/?lang=en" target="_blank" rel="noopener noreferrer">https://www.scielo.br/j/rpp/a/pXSnBjMQKDX43kK5xtJdnCJ/?lang=en</a>
                        </p>
                        <p>
                            Robins DL, Casagrande K, Barton M, Chen CM, Dumont-Mathieu T, Fein D. Validation of the modified checklist autism in toddlers, revised with follow-up (M-CHAT-R/F). Pediatrics. 2014;133:37-45. <a href="https://doi.org/10.1542/peds.2013-1813" target="_blank" rel="noopener noreferrer">https://doi.org/10.1542/peds.2013-1813</a>
                        </p>
                        <p>
                            Robins DL, Fein D, Barton ML, Green JA. The Modified Checklist for Autism in Toddlers: an initial study investigating the early detection of autism and pervasive developmental disorders. J Autism Dev Disord. 2001;31:131-44. <a href="https://doi.org/10.1023/a:1010738829569" target="_blank" rel="noopener noreferrer">https://doi.org/10.1023/a:1010738829569</a>
                        </p>
                        <p>
                            Chlebowski C, Robins DL, Barton ML, Fein D. Large-scale use of the modified checklist for autism in low-risk toddlers. Pediatrics. 2013;131:e1121-7. <a href="https://doi.org/10.1542/peds.2012-1525" target="_blank" rel="noopener noreferrer">https://doi.org/10.1542/peds.2012-1525</a>
                        </p>
                        <p>
                            Google Imagens, 2025.
                        </p>
                    </div>
                </div>
            </div>
        </Header>
    );
}

export default MaisInformacoes;