import React from 'react';
import Header from '../../components/siderbar';
import './styles.css';
import { useTranslation } from 'react-i18next';

import InfoCard from '../../components/infoCard/InfoCard';
import questionIcon from '../../assets/info.png';
import FilePolitica from '../../docs/Política de Privacidade.pdf'

function PoliticaPrivacidade() {
    const { t } = useTranslation();
    const pdfPath = FilePolitica;

    return (
        <Header>
            <InfoCard title={t("privacy_policy")} icon={questionIcon} />
            <div className='home-container'>
                <div className="informacoes-container">
                    <h2 className="informacoes-titulo">{t("privacy_policy_title")}</h2>
                    <p className="informacoes-subtitulo">{t("privacy_policy_subtitle")}</p>
                    

                    <div className="pdf-container">
                        <iframe 
                            src={pdfPath} 
                            width="100%" 
                            height="800px" 
                            title="Política de Privacidade"
                        />
                    </div>

                </div>
            </div>
        </Header>
    );
}

export default PoliticaPrivacidade;
