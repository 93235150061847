// PerfilUser.js
import React, { useEffect, useState } from 'react';
import Header from '../../components/siderbar';
import './styles.css';
import { FaUser, FaKey, FaLanguage, FaFileAlt, FaShieldAlt, FaShareAlt, FaArrowRight } from 'react-icons/fa';
import { getAuthHeaders } from '../../services/authHeaders';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../components/seletor'; // Importa o seletor de idioma


import { Modal2 } from '../../components/modal'; // Importa o componente de modal
import { useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';

import { FaArrowLeft } from 'react-icons/fa';

function PerfilUser() {
    const { t } = useTranslation();
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userProfissao, setUserProfissao] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar o modal

    useEffect(() => {
        const fetchUserProfile = async () => {
            const userId = localStorage.getItem('userId');
            try {
                const response = await fetch(`https://apiprojetotea.squareweb.app/usuario/${userId}`, {
                    method: 'GET',
                    headers: getAuthHeaders(),
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserName(data.usuario.nome_completo);
                    setUserEmail(data.usuario.email);
                    setUserProfissao(data.usuario.profissao);
                } else {
                    console.error(t('fetch_user_data_error'));
                }
            } catch (error) {
                console.error(t('server_connection_error'), error);
            }
        };

        fetchUserProfile();
    }, [t]);

    // Função para abrir o modal
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Função para fechar o modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const navigate = useNavigate()

    function gobackNav(params) {
        navigate(-1)
    }

    // Função para copiar a URL do site para a área de transferência e exibir um alerta
    const copySiteURL = () => {
        const url = window.location.origin; // Obtém a URL base
        navigator.clipboard.writeText(url)
            .then(() => {
                Swal.fire({
                    title: 'Copiado!',
                    text: 'A URL foi copiada com sucesso para a área de transferência.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            })
            .catch(err => {
                Swal.fire({
                    title: 'Erro!',
                    text: 'Houve um problema ao copiar a URL.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                console.error("Erro ao copiar a URL: ", err);
            });
    };


    return (
        <Header>
            <div className="perfil-container">
                <div className="perfil-header">
                    <h1>{t("my_profile")}</h1>
                </div>

                <div className="perfil-content">

                    <div className="back-div-voltar-pf" onClick={gobackNav}>
                        <FaArrowLeft className="back-icon" />
                    </div>
                    <div className="perfil-card">

                        <div className="perfil-info">
                            <div className="perfil-icon">
                                <FaUser size={60} />
                            </div>
                            <h2>{userName}</h2>
                            <p>{userEmail}</p>
                            <span>{userProfissao}</span>
                        </div>
                    </div>

                    <div className="perfil-settings">
                        <div className="settings-section">
                            <h3>{t("settings")}</h3>
                            <div className="settings-item">
                                <Link to="/editar-perfil" className='content-link'>
                                    <div>
                                        <FaUser className="settings-icon" />
                                        <span>{t("edit_profile")}</span>
                                    </div>
                                    <FaArrowRight className="arrow-icon" />
                                </Link>
                            </div>
                            <div className="settings-item">
                                <Link to="/mudar-senha" className='content-link'>
                                    <div>
                                        <FaKey className="settings-icon" />
                                        <span>{t("change_password")}</span>
                                    </div>
                                    <FaArrowRight className="arrow-icon" />
                                </Link>
                            </div>
                            <div className="settings-item" onClick={openModal}> {/* Abre o modal */}
                                <div>
                                    <FaLanguage className="settings-icon" />
                                    <span>{t("language")}</span>
                                </div>
                                <FaArrowRight className="arrow-icon" />
                            </div>
                        </div>

                        <div className="settings-section">
                            <h3>{t("information")}</h3>
                            <div className="settings-item">

                                <Link to="/termos" className='content-link'>
                                    <div>
                                        <FaFileAlt className="settings-icon" />
                                        <span>{t("terms_conditions")}</span>
                                    </div>
                                    <FaArrowRight className="arrow-icon" />
                                </Link>
                            </div>
                            <div className="settings-item">

                                <Link to="/politica-privacidade" className='content-link'>
                                    <div>
                                        <FaShieldAlt className="settings-icon" />
                                        <span>{t("privacy_policy")}</span>
                                    </div>
                                </Link>

                                <FaArrowRight className="arrow-icon" />
                            </div>
                            <div className="settings-item" onClick={copySiteURL}>
                                <div>
                                    <FaShareAlt className="settings-icon" />
                                    <span>{t("share")}</span>
                                </div>
                                <FaArrowRight className="arrow-icon" />
                            </div>

                        </div>
                    </div>
                </div>

                {/* Modal para seleção de idioma */}
                {isModalOpen && (
                    <Modal2 onClose={closeModal} title={t("select_language")}>
                        <LanguageSelector /> {/* Exibe o componente de seleção de idioma */}
                    </Modal2>
                )}
            </div>
        </Header>
    );
}

export default PerfilUser;
