import React, { useState } from 'react';
import Header from '../../components/siderbar';
import './styles.css';
import { FaArrowRight, FaInfoCircle } from 'react-icons/fa';
import PerguntasMchatRf from '../../components/mchatrf/PerguntasMchatRf';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import InfoCard from '../../components/infoCard/InfoCard';
import questionIcon from '../../assets/m-chat-rf.png';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, differenceInMonths, parseISO } from 'date-fns';

function CadastroMchatRf() {
    const { t } = useTranslation();

    const [showPerguntas, setShowPerguntas] = useState(false);
    const [formData, setFormData] = useState({
        nomeCrianca: '',
        dataNascimento: '',
        responsavel: '',
        numeroContato: '',
        endereco: '',
        cidade: ''
    });

    const [isValidAge, setIsValidAge] = useState(false);

    const handleContinue = () => {
        if (Object.values(formData).some(field => typeof field === 'string' && field.trim() === '')) {
            Swal.fire({
                icon: 'warning',
                title: t('required_fields_title'),
                text: t('required_fields_text'),
                confirmButtonText: 'OK',
                confirmButtonColor: '#4a63d0'
            });
        } else if (!isValidAge) {
            Swal.fire({
                icon: 'error',
                title: t('invalid_age_title'),
                text: t('invalid_age_text'),
                confirmButtonText: 'OK',
                confirmButtonColor: '#d33'
            });
        } else {
            setShowPerguntas(true);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const ClickInfo = () => {
        Swal.fire({
            icon: 'warning',
            title: t('invalid_age_title'),
            text: t('invalid_age_text'),
            confirmButtonText: 'OK',
            confirmButtonColor: '#dc8a32'
        });
    }

    const handleDateChange = (date) => {
        if (date) {
            const formattedDate = format(date, 'yyyy-MM-dd');
            setFormData((prevData) => ({
                ...prevData,
                dataNascimento: formattedDate
            }));

            const monthsOld = differenceInMonths(new Date(), date);
            if (monthsOld >= 16 && monthsOld <= 30) {
                setIsValidAge(true);
            } else {
                setIsValidAge(false);
                Swal.fire({
                    icon: 'warning',
                    title: t('invalid_age_title'),
                    text: t('invalid_age_text'),
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#dc8a32'
                });
            }
        } else {
            setIsValidAge(false);
        }
    };

    return (
        <Header>
            <InfoCard title="M-CHAT-R/F" icon={questionIcon} />
            {!showPerguntas ? (
                <>
                    <div className="mchat-container">
                        <div className="mchat-header">
                            <p>{t('mchat_description')}</p>
                        </div>
                        <div className="mchat-form">
                            <input
                                type="text"
                                name="nomeCrianca"
                                placeholder={t("child_name")}
                                onChange={handleChange}
                            />
                            <div className="date-picker-wrapper">
                                <div className="date-picker-container" style={{ display: 'flex', alignItems: 'center' }}>


                                    <label className={`date-picker-label ${formData.dataNascimento ? "has-value" : ""}`}>
                                        {t("dob_label")}


                                    </label>
                                    <ReactDatePicker
                                        selected={formData.dataNascimento ? parseISO(formData.dataNascimento) : null}
                                        onChange={handleDateChange}
                                        placeholderText={t("dob_placeholder_date")}
                                        dateFormat={t("dob_placeholder_date")}
                                        className="date-picker-input"
                                    />
                                </div>
                                <FaInfoCircle
                                    className="icon-i "
                                    onClick={ClickInfo}
                                    title={t("age_restriction_info")}
                                
                                />

                            </div>

                            <input
                                type="text"
                                name="responsavel"
                                placeholder={t("responsible")}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="numeroContato"
                                placeholder={t("contact_number")}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="endereco"
                                placeholder={t("address")}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="cidade"
                                placeholder={t("city")}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='button-org-info-cadastro-m-chat'>
                            <button className="continue-btn" onClick={handleContinue} disabled={!isValidAge}>
                                {t("continue")} <FaArrowRight />
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                <PerguntasMchatRf formData={formData} />
            )}
        </Header>
    );
}

export default CadastroMchatRf;