// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Rotas Livres
import InstitutoAutismo from './pages/inicio';
import Login from './pages/login';
import Cadastro from './pages/cadastro';
import RecSenha from './pages/rec_senha';
import NovaSenha from './pages/new-senha';

// Rotas privadas
import PrivateRoute from './services/PrivateRoute';
import HomePage from './pages/home';
import CadastroMchatRf from './pages/m-chat-rf';
import TutotialMchat from './pages/tutorial-m-chat-rf';
import ProcessoTriagem from './pages/processo-triagem';
import NiveisTEA from './pages/niveis-tea';
import Duvidas from './pages/duvidas';
import MaisInfo from './pages/mais-info';
import PerfilUser from './pages/perfil-user';
import EditarPerfil from './pages/editar-perfil';
import MudarSenha from './pages/mudar-senha';
import DetalhesTEA from './pages/detalhes-tea';
import MobileList from './components/MobileList';
import PoliticaPrivacidade from './pages/politica';
import Termos from './pages/termos';



function Rotas() {
    return (
        <Router>
            <Routes>
                {/* Rota protegida para HomePage */}
                <Route
                    path="/home-page"
                    element={
                        <PrivateRoute>
                            <HomePage />
                        </PrivateRoute>
                    }
                />
                
                {/* Rota protegida para Cadastro M-CHAT-R/F */}
                <Route
                    path="/cadastro-m-chat-r-f"
                    element={
                        <PrivateRoute>
                            <CadastroMchatRf />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/tutorial-m-chat-rf"
                    element={
                        <PrivateRoute>
                            <TutotialMchat />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/processo-triagem"
                    element={
                        <PrivateRoute>
                            <ProcessoTriagem />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/niveis-tea"
                    element={
                        <PrivateRoute>
                            <NiveisTEA />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/termos"
                    element={
                        <PrivateRoute>
                            <Termos />
                        </PrivateRoute>
                    }
                />
                
                <Route
                    path="/duvidas-sugestoes"
                    element={
                        <PrivateRoute>
                            <Duvidas />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/mais-informacoes"
                    element={
                        <PrivateRoute>
                            <MaisInfo />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/politica-privacidade"
                    element={
                        <PrivateRoute>
                            <PoliticaPrivacidade />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/perfil-user"
                    element={
                        <PrivateRoute>
                            <PerfilUser />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/editar-perfil"
                    element={
                        <PrivateRoute>
                            <EditarPerfil />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/mudar-senha"
                    element={
                        <PrivateRoute>
                            <MudarSenha />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/detalhe-tea"
                    element={
                        <PrivateRoute>
                            <DetalhesTEA />
                        </PrivateRoute>
                    }
                />
                
                <Route
                    path="/lista-teas"
                    element={
                        <PrivateRoute>
                            <MobileList />
                        </PrivateRoute>
                    }
                />
                
                {/* Rota pública para a página inicial */}
                <Route path="/" element={<InstitutoAutismo />} />
                <Route path="/login" element={<Login />} />
                <Route path="/cadastro" element={<Cadastro />} />
                <Route path="/recuperando-senha" element={<RecSenha />} />
                <Route path="/recuperando-senha-new" element={<NovaSenha />} />


            </Routes>
        </Router>
    );
}

export default Rotas;
